import React from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from '../sidebar/Sidebar';
import './Layout.css';

const Layout = ({ children }) => {
  const location = useLocation();
  const isLoginRoute = location.pathname === '/login';

  return (
    <div className="screen">
      {!isLoginRoute ? (
        <div className="layout">
          <Sidebar />
          <div className="content">
            {children}
          </div>
        </div>
      ) : (
        <div className="unlogged-layout">
          <div className="unlogged-content">
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default Layout;
