import React, { createContext, useState } from 'react';

export const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    name: '',
    iaName: '',
    iaUsed: 'Não escolhida',
    iaVersion: '',
    useAgents: false,
    agents: [],
    globalPrompt: '',
  });

  return (
    <FormContext.Provider value={{ formData, setFormData }}>
      {children}
    </FormContext.Provider>
  );
};
