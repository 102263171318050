import './ConfigurationForm.css';

const ConfigurationForm = ({ nextStep, prevStep, handleChange, handleSwitchChange, formData, isEdit }) => {
  return (
    <div className="configuration-form-container">
      <h2>
        {isEdit ? `Editar Configuração - ${formData.name}` : 'Nova Configuração'}
      </h2>

      {/* Campo de nome da configuração */}
      <div className="config-section">
        <label>Nome da Configuração</label>
        <input type="text" value={formData.name} onChange={handleChange('name')} />
      </div>

      <div className="config-section">
        <label>Nome da IA</label>
        <input type="text" value={formData.iaName} onChange={handleChange('iaName')} />
      </div>

      {/* Seção de Seleção da IA */}
      <div className="config-section">
        <label>Selecione a IA</label>
        <select value={formData.iaUsed} onChange={handleChange('iaUsed')}>
          <option value="">Selecione...</option>
          <option value="llama">Llama</option>
          <option value="openai">OpenAI</option>
          <option value="gemini">Gemini</option>
        </select>
      </div>

      <div className="config-section">
        <label>Versão da IA</label>
        <input type="text" value={formData.iaVersion} onChange={handleChange('iaVersion')} />
      </div>

      {/* Seção de Configuração de Agentes e Funções */}
      <div className="config-section">
        <label>
          <span className="switch-label">Usar Agentes</span>
          <input
            className="switch"
            type="checkbox"
            checked={formData.useAgents}
            onChange={(e) => handleSwitchChange('useAgents')(e.target.checked)}
          />
        </label>
      </div>

      {/* Campos menores e inline */}
      {formData.useAgents && (
        <div className="inline-fields">
          <div className="config-section">
            <label>Nome do agente supervisor</label>
            <input type="text" value={formData.supervisorName} onChange={handleChange('supervisorName')} />
          </div>

          <div className="config-section">
            <label>Nome do agente técnico</label>
            <input type="text" value={formData.techName} onChange={handleChange('techName')} />
          </div>

          <div className="config-section">
            <label>Nome do agente guardião</label>
            <input type="text" value={formData.guardianName} onChange={handleChange('guardianName')} />
          </div>
        </div>
      )}

      <div className="configuration-buttons">
        <button onClick={prevStep}>Cancelar</button>
        <button onClick={nextStep} disabled={!formData.iaVersion}>Próximo</button>
      </div>
    </div>
  );
};

export default ConfigurationForm;
