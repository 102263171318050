import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './NewChat.css';
import ApiService from '../../../../services/apiService';

const NewChat = () => {
  const [chatName, setChatName] = useState('');
  const [configs, setConfigs] = useState([]);
  const [selectedConfig, setSelectedConfig] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    ApiService.get('/api/v1/settings').then((response) => {
      setConfigs(response);
    });
  }, []);

  const handleCreateChat = async () => {
    if (chatName && selectedConfig) {
      try {
        await ApiService.post('/api/v1/chats', {
          name: chatName,
          settingId: selectedConfig,
        })
        .then((newChat) => {
          setChatName('');
          setSelectedConfig('');

          console.log('Chat criado:', newChat);
          navigate(`/chat/${newChat.id}`);
        })
        .catch((error) => {
          console.error('Erro ao criar chat:', error);
        });
      } catch (error) {
        console.error('Erro ao criar chat:', error);
      }
    } else {
      alert('Por favor, insira um nome para o chat e selecione uma configuração.');
    }
  };

  return (
    <div className="new-chat-form-container">
      <h2>Novo Chat</h2>
      <div className="form-group">
        <label>Nome do Chat:</label>
        <input
          type="text"
          value={chatName}
          onChange={(e) => setChatName(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Configuração:</label>
        <select
          value={selectedConfig}
          onChange={(e) => setSelectedConfig(e.target.value)}
        >
          <option value="">Selecione uma configuração...</option>
          {configs.map((config) => (
            <option key={config.id} value={config.id}>
              {config.name}
            </option>
          ))}
        </select>
      </div>
      <button onClick={handleCreateChat} className="create-chat-button">
        Criar Chat
      </button>
    </div>
  );
};

export default NewChat;
