import React, { useState } from 'react';
import './AgentForm.css';
import ConfirmationModal from '../confirmationModal/ConfirmationModal';
import AgentOptions from './AgentOptions';
import AgentInput from './AgentInput';

const AgentForm = ({ addAgent, prevStep, nextStep, formData, editAgent, removeAgent }) => {
  const [agent, setAgent] = useState({
    slug: '',
    description: '',
    prompt: '',
    includeIaName: false,
    includeDateTime: false,
    includeOtherAgents: false,
    includeLastOutput: false,
    includeCompanyName: false,
    isTechnical: false,
    useOnSupervisor: false,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (input) => (e) => {
    setAgent({ ...agent, [input]: e.target.value });
  };

  const handleToggle = (input) => () => {
    setAgent({ ...agent, [input]: !agent[input] });
  };

  const openModal = () => {
    if (isEditing) {
      saveAgent();
      nextStep();
      return;
    }

    if (!agent.slug && !agent.description && !agent.prompt && formData.agents.length > 0) {
      nextStep();
      return;
    }

    if (agent.slug && agent.description) {
      setIsModalOpen(true);
    } else {
      alert("Preencha todos os campos do agente antes de salvar.");
    }
  };

  const saveAgent = () => {
    if (isEditing) {
      editAgent(agent);
    } else {
      addAgent(agent);
    }
    resetForm();
    setIsModalOpen(false);
  };

  const resetForm = () => {
    setAgent({
      slug: '',
      description: '',
      prompt: '',
      includeIaName: false,
      includeDateTime: false,
      includeOtherAgents: false,
      includeLastOutput: false,
      includeCompanyName: false,
      isTechnical: false,
      useOnSupervisor: false,
    });

    setIsEditing(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleAddAnother = () => {
    saveAgent();
    resetForm();
    setIsModalOpen(false);
  };

  const handleFinishConfiguration = () => {
    saveAgent();
    nextStep();
  };

  const loadAgentToEdit = (agent) => {
    setAgent(agent);
    setIsEditing(true);
  };

  const handleRemoveAgent = (slug) => {
    const updatedAgents = formData.agents.filter((ag) => ag.slug !== slug);
    removeAgent(updatedAgents);
  };

  return (
    <div className="agent-form-container">
      <h2>{isEditing ? `Editar Agente - ${agent.slug}` : 'Adicionar Agente'}</h2>
      <AgentOptions agent={agent} handleToggle={handleToggle} />
      <AgentInput agent={agent} handleChange={handleChange} />
      <hr />
      <div className="agent-buttons">
        <button onClick={prevStep}>Voltar</button>
        <button onClick={openModal}>
          {isEditing ? 'Salvar Alterações' : (!agent.slug && !agent.description && !agent.prompt) ? 'Finalizar' : 'Salvar'}
        </button>
      </div>
      {isModalOpen && (
        <ConfirmationModal
          onClose={handleModalClose}
          onAddAnother={handleAddAnother}
          onFinish={handleFinishConfiguration}
        />
      )}
      <div className="added-agents">
        <h3>Agentes Adicionados:</h3>
        {formData.agents.length > 0 ? (
          formData.agents.map((ag, index) => (
            <div key={index} className="agent-item">
              <div onClick={() => loadAgentToEdit(ag)}>
                <strong>Slug:</strong> {ag.slug} <br />
                <strong>Descrição:</strong> {ag.description}
              </div>
              <button onClick={() => handleRemoveAgent(ag.slug)} className="remove-button">Remover</button>
            </div>
          ))
        ) : (
          <p>Nenhum agente adicionado.</p>
        )}
      </div>
    </div>
  );
};

export default AgentForm;
