import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './ViewChat.css';

import ApiService from '../../../../services/apiService';
import ChatView from '../../../../components/chats/chatView/ChatView';
import MessageInput from '../../../../components/chats/messageInput/MessageInput';
import SettingsSummary from '../../../../components/chats/settingsSummary/SettingsSummary';

const ViewChat = () => {
  const { id } = useParams();
  const [chat, setChat] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    ApiService.get(`/api/v1/chats/${id}`).then((response) => {
      setChat(response);
      setLoading(false);
    });
  }, [id]);

  const handleSendMessage = async (message) => {
    const updatedMessages = [...chat.messages, {
      role: 'user', content: message, createdAt: new Date().toLocaleString('pt-BR'),
    }];

    setChat({ ...chat, messages: updatedMessages });

    try {
      await ApiService.post(`/api/v1/chats/${id}/messages`, {
        message: {
          content: message,
          role: 'user',
        }
      })
      .then((response) => {
        setChat((prevChat) => ({
          ...prevChat,
          messages: [...prevChat.messages, {
            role: 'assistant', content: response.answer,
            iaOutput: { iaOutput: response.iaOutput }, createdAt: response.createdAt,
          }],
        }));
      })
      .catch((error) => {
        console.error('Erro ao enviar mensagem:', error);
      });
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <p>Carregando chat...</p>
      </div>
    );
  }

  return (
    <div className="chat-view-page-container">
      <div className="chat-section">
        <ChatView messages={chat.messages} />
        <MessageInput onSendMessage={handleSendMessage} />
      </div>
      <div className="config-summary-section">
        <SettingsSummary setting={chat.setting} agents={chat.agents} />
      </div>
    </div>
  );
};

export default ViewChat;
