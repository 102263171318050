import React, { useState } from 'react';
import './GlobalPromptForm.css';

const GlobalPromptForm = ({ nextStep, prevStep, handleChange, formData }) => {
  return (
    <div className="global-prompt-form-container">
      <h2>Cadastrar Prompt Global</h2>
      <div className="prompt-input">
        <textarea
          placeholder="Insira o prompt global aqui..."
          value={formData.globalPrompt || ''}
          onChange={handleChange('globalPrompt')}
        />
      </div>

      <div className="form-buttons">
        <button onClick={prevStep}>Voltar</button>
        <button onClick={nextStep}>Avançar</button>
      </div>
    </div>
  );
};

export default GlobalPromptForm;
