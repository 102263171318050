import './SettingsSummary.css';
import ExpandText from 'react-expand-text';

const SettingsSummary = ({ setting, agents }) => {

  const handleAgentsDescription = () => {
    const description = agents.map((agent) => `${agent.slug} - ${agent.description}`).join(" \n\n ");

    return description;
  };

  return (
    <div className="setting-summary-container">
      <h3>Resumo da Configuração</h3>
      <p><strong>Nome:</strong> {setting.name}</p>
      <p><strong>IA Usada:</strong> {setting.iaName}</p>
      <p><strong>Versão da IA:</strong> {setting.iaVersion}</p>
      <p><strong>Usa Agentes?</strong> {setting.useAgents ? 'Sim' : 'Não'}</p>
      {
        setting.useAgents && (
          <div>
            <p><strong>Quantidade de Agentes:</strong> {agents && agents.length}</p>
            <h4>Descrição dos Agentes</h4>
            <p>
              <ExpandText
                maxLength={150}
                text={handleAgentsDescription()}
              />
            </p>
          </div>
        )
      }
      {
        !setting.useAgents && (
          <p>
            <strong>Prompt Global<br /><span className="hint">clique no texto para expandir <br /></span></strong>
            <span className='global-prompt'>
              <ExpandText
                maxLength={60}
                text={setting.globalPrompt}
              />
            </span>
          </p>
        )
      }
    </div>
  );
};

export default SettingsSummary;
