import React from 'react';
import './Summary.css';

const Summary = ({ formData, showSaveButton, handleSubmit }) => {
  return (
    <div className="summary-container">
      <h3>Configuração Atual</h3>
      <ul>
        <li><strong>Nome:</strong> {formData.name}</li>
        <li><strong>IA Usada:</strong> {formData.iaUsed}</li>
        <li><strong>Versão da IA:</strong> {formData.iaVersion}</li>
        <li><strong>Usa Agentes:</strong> {formData.useAgents ? 'Sim' : 'Não'}</li>
        <li><strong>Usa Funções:</strong> {formData.useFunctions ? 'Sim' : 'Não'}</li>
        {formData.useFunctions && (
          <li>
            <strong>Código das Functions:</strong>
            <pre className="code-block">{formData.functionsCode}</pre>
          </li>
        )}
        {formData.useAgents && (
          <li>
            <strong>Agentes:</strong>
            <ul className="agents-list">
              {formData.agents.map((agent, index) => (
                <li key={index} className="agent-item">
                  <strong>Slug:</strong> {agent.slug} <br />
                  <strong>Descrição:</strong> {agent.description}
                </li>
              ))}
            </ul>
          </li>
        )}
      </ul>

      {showSaveButton && (
        <button onClick={handleSubmit} className="save-button">
          Salvar Configuração
        </button>
      )}

      {
        formData.globalPrompt && (
          <div>
            <hr />
            <h4>Prompt - Visualização</h4>
            <p>{formData.globalPrompt}</p>
          </div>
        )
      }
    </div>
  );
};

export default Summary;
