import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import './ListChats.css';

import ClipLoader from 'react-spinners/ClipLoader';
import ApiService from '../../../../services/apiService';
import { FormContext } from '../../../../context/FormContext';

const ListChats = () => {
  const [chats, setchats] = useState(null);
  const [loading, setLoading] = useState(true);
  const { formData, setFormData } = useContext(FormContext);
  const navigate = useNavigate();

  useEffect(() => {
    ApiService.get('/api/v1/chats').then((response) => {
      console.log(response);
      setchats(response);
      setLoading(false);
    });

    setFormData({
      id: '',
      name: '',
      iaName: '',
      iaUsed: 'Não escolhida',
      iaVersion: '',
      useAgents: false,
      agents: [],
      globalPrompt: '',
    });
  }, []);

  const handleView = (id) => {
    navigate(`/chat/${id}`);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-card">
          <ClipLoader color="#1abc9c" loading={loading} size={50} />
          <p>Carregando chats...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="list-chats-container">
      <h1>Chats</h1>
      <button onClick={() => navigate('/new-chat')} className="new-chat-button">Iniciar Nova Conversa</button>
      <hr />
      <table className="chats-table">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Configuração</th>
            <th>Quantidade de Mensagens</th>
            <th>Usa Agentes?</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {chats.map((chat, index) => (
            <tr key={index}>
              <td>{chat.name}</td>
              <td>{chat.setting.name}</td>
              <td>{chat.messages.length}</td>
              <td>{chat.setting.useAgents ? 'Sim' : 'Não'}</td>
              <td>
                <button onClick={() => handleView(chat.id)} className="action-button">Ver</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListChats;
