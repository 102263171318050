import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './ViewConfig.css';

import ClipLoader from 'react-spinners/ClipLoader';
import ApiService from '../../../../services/apiService';

const ViewConfig = () => {
  const { id } = useParams();
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    ApiService.get(`/api/v1/settings/${id}`).then((response) => {
      setConfig(response);
      setLoading(false);
    });
  }, [id]);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-card">
          <ClipLoader color="#1abc9c" loading={loading} size={50} />
          <p>Carregando configuração...</p>
        </div>
      </div>
    );
  }

  const goToEdit = () => () => {
    navigate(`/config/${id}/edit`);
  }

  return (
    <div className="view-config-container">
      <h1>Visualizar Configuração</h1>
      <div className="config-details">
        <p><strong>Nome:</strong> {config.name}</p>
        <p><strong>Nome da IA:</strong> {config.ia_name}</p>
        <p><strong>Usa Agentes?</strong> {config.use_agents ? 'Sim' : 'Não'}</p>
        <p><strong>Quantidade de Agentes:</strong> {config.agents ? config.agents.length : 0}</p>
        {/* Adicione mais detalhes conforme necessário */}
      </div>

      <div className="actions">
        <button
          className="action-button"
          onClick={ goToEdit() }
        >
          Editar
        </button>

        <button
          className="action-button"
          onClick={ () => { navigate('/list-configs') } }
        >
          Voltar
        </button>
      </div>
    </div>
  );
};

export default ViewConfig;
