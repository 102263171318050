import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormContext } from '../../../../context/FormContext';

import ConfigurationForm from '../../../../components/configs/configurationForm/ConfigurationForm';
import AgentForm from '../../../../components/configs/agentForm/AgentForm';
import GlobalPromptForm from '../../../../components/configs/globalPromptForm/GlobalPromptForm';
import Summary from '../../../../components/configs/summary/Summary';

import ApiService from '../../../../services/apiService';

const NewConfig = () => {
  const [step, setStep] = useState(1);
  const { formData, setFormData } = useContext(FormContext);
  const navigate = useNavigate();

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  const handleSwitchChange = (input) => (value) => {
    setFormData({ ...formData, [input]: value });
  };

  const addAgent = (agent) => {
    setFormData({ ...formData, agents: [...formData.agents, agent] });
  };

  const editAgent = (agent) => {
    const updatedAgents = formData.agents.map((ag) => {
      if (ag.slug === agent.slug) {
        return agent;
      }
      return ag;
    });

    setFormData({ ...formData, agents: updatedAgents });
  };

  const handleSubmit = () => {
    ApiService.post(`/api/v1/settings`, formData).then(() => {
      navigate('/list-configs');
    });
  };

  const removeAgent = (updatedAgents) => {
    setFormData({ ...formData, agents: updatedAgents });
  };

  const cancel = () => {
    setFormData({
      id: '',
      name: '',
      iaName: '',
      iaUsed: 'Não escolhida',
      iaVersion: '',
      useAgents: false,
      agents: [],
      globalPrompt: '',
    });

    navigate('/');
  };


  return (
    <div>
      {step === 1 && (
        <ConfigurationForm
          nextStep={nextStep}
          prevStep={cancel}
          handleChange={handleChange}
          handleSwitchChange={handleSwitchChange}
          formData={formData}
          isEdit={false} // Indica que estamos criando uma nova configuração
        />
      )}
      {step === 2 && (
        formData.useAgents ? (
          <AgentForm
            addAgent={addAgent}
            nextStep={nextStep}
            prevStep={prevStep}
            formData={formData}
            editAgent={editAgent}
            removeAgent={removeAgent}
          />        ) : (
          <GlobalPromptForm nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} formData={formData} />
        )
      )}
      {step === 3 && (
        <Summary formData={formData} prevStep={prevStep} handleSubmit={handleSubmit} showSaveButton={true} />
      )}
    </div>
  );
};

export default NewConfig;
