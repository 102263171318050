import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import './Sidebar.css';

const Sidebar = () => {
  const { logout } = useContext(AuthContext);

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="sidebar">
      <div className="header">
        <h1>F.AI</h1>
        <p>Playground</p>
      </div>
      <nav>
        <ul>
          <li><NavLink to="/list-configs" activeClassName="active">Configurações</NavLink></li>
          <li><NavLink to="/list-chats" activeClassName="active">Chats</NavLink></li>
        </ul>
      </nav>
      <div className="logout-section">
        <button onClick={handleLogout} className="logout-button">Sair</button>
      </div>
    </div>
  );
};

export default Sidebar;
