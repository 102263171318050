import React from 'react';

const AgentOptions = ({ agent, handleToggle }) => {
  return (
    <div className="agent-options">
      <h4>Dados extras a inserir no Prompt:</h4>
      <div className="option-buttons">
        <button className={agent.includeIaName ? 'selected' : ''} onClick={handleToggle('includeIaName')}>[Nome da IA]</button>
        <button className={agent.includeDateTime ? 'selected' : ''} onClick={handleToggle('includeDateTime')}>[Data e Hora]</button>
        <button className={agent.includeOtherAgents ? 'selected' : ''} onClick={handleToggle('includeOtherAgents')}>[Dados de outros Agentes]</button>
        <button className={agent.includeLastOutput ? 'selected' : ''} onClick={handleToggle('includeLastOutput')}>[Saída do agente anterior]</button>
        <button className={agent.includeCompanyName ? 'selected' : ''} onClick={handleToggle('includeCompanyName')}>[Nome da Empresa]</button>
      </div>

      <h4>Configurações do Agente:</h4>
      <div className="option-buttons">
        <button className={agent.isTechnical ? 'selected' : ''} onClick={handleToggle('isTechnical')}>É um agente técnico</button>
        <button className={agent.useOnSupervisor ? 'selected' : ''} onClick={handleToggle('useOnSupervisor')}>Incluir no Supervisor</button>
      </div>
    </div>
  );
};

export default AgentOptions;
