import React from 'react';

const AgentInput = ({ agent, handleChange }) => {
  return (
    <div className="agent-input">
      <input
        type="text"
        placeholder="Slug"
        value={agent.slug}
        onChange={handleChange('slug')}
      />
      <input
        type="text"
        placeholder="Descrição"
        value={agent.description}
        onChange={handleChange('description')}
      />
      <textarea
        placeholder="Prompt"
        value={agent.prompt}
        onChange={handleChange('prompt')}
      />
    </div>
  );
};

export default AgentInput;
