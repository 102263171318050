import React, { useState, useEffect } from 'react';
import './MessageModal.css';

const MessageModal = ({ message, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [sender, setSender] = useState('IA');
  const [totalTokens, setTotalTokens] = useState(0);

  useEffect(() => {
    if (message && message.iaOutput) {
      const {
        supervisorResponse, dynamicAgentOutput,
        guardianResponse, techResponse
      } = message.iaOutput.iaOutput;

      const guardianContent = guardianResponse ? {
        adjustedMessage: guardianResponse.adjustedMessage,
        status: guardianResponse.status,
      } : 'Não executado.';

      const newSteps = [
        {
          role: 'Supervisor',
          content: supervisorResponse ? `Agente recomendado: ${supervisorResponse.recommendedAgent}` : '',
          promptTokens: supervisorResponse ? supervisorResponse.promptTokens : 0,
          outputTokens: supervisorResponse ? supervisorResponse.outputTokens : 0,
          totalTokens: supervisorResponse ? supervisorResponse.promptTokens + supervisorResponse.outputTokens : 0,
        },
        {
          role: supervisorResponse ? supervisorResponse.recommendedAgent : 'Agente Recomendado',
          content: 'Mensagem acima.',
          promptTokens: dynamicAgentOutput ? dynamicAgentOutput.promptTokens : 0,
          outputTokens: dynamicAgentOutput ? dynamicAgentOutput.outputTokens : 0,
          totalTokens: dynamicAgentOutput ? dynamicAgentOutput.promptTokens + dynamicAgentOutput.outputTokens : 0,
        },
        {
          role: 'Guardião',
          content: JSON.stringify(guardianContent),
          promptTokens: (
            guardianResponse && guardianResponse.promptTokens
           ) || 0,
          outputTokens: (
            guardianResponse && guardianResponse.outputTokens
          ) || 0,
          totalTokens: ((
            guardianResponse && guardianResponse.promptTokens
          ) || 0) + ((
            guardianResponse && guardianResponse.outputTokens
          ) || 0),
        },
        {
          role: 'Técnico',
          content: techResponse ? JSON.stringify(techResponse.actions) : 'Não Executado.',
          promptTokens: (
            techResponse && techResponse.promptTokens
          ) || 0,
          outputTokens: (
            techResponse && techResponse.outputTokens
          ) || 0,
          totalTokens: ((
            techResponse && techResponse.promptTokens
          ) || 0) + ((
            techResponse && techResponse.outputTokens
          ) || 0),
        },
      ];

      setSender(supervisorResponse.recommendedAgent);
      setTotalTokens(newSteps.reduce((acc, step) => acc + step.totalTokens, 0));
      setSteps(newSteps);
    }
  }, [message]);

  useEffect(() => {
    if (steps.length > 0) {
      const interval = setInterval(() => {
        setCurrentStep((prevStep) => (prevStep + 1) % steps.length);
      }, 2000); // Muda o passo a cada 2 segundos

      return () => clearInterval(interval);
    }
  }, [steps]);

  if (!message) {
    return null;
  }

  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <p>Mensagem enviada pelo agente {sender} em {message.createdAt}.</p>
        <p className="message-content">{message.content}</p>
        <p></p>

        <div className="animation-container">
          <div className="progress-line" style={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }} />
          {steps.map((step, index) => (
            <div key={index} className={`step ${index <= currentStep ? 'active' : ''}`}>
              <div className="title">{step.role}</div>
              <div className={`circle ${step.content ? 'filled' : 'empty'}`} />
              <div className="content">{step.content}</div>
              <div className="details">
                <div className="tokens">
                  <div className="title">Tokens</div>
                  <div className="input-tokens">Entrada: {step.promptTokens}</div>
                  <div className="output-tokens">Saída: {step.outputTokens}</div>
                  <div className="total-tokens">Total: {step.totalTokens}</div>
                </div>
              </div>
            </div>
          ))}

          <div className="details2">
            <div className="tokens">
              <div className="title">Tokens Totais da Interação</div>
              <div className="total-tokens">{ totalTokens }</div>
            </div>
          </div>
        </div>

        <button className="close-modal" onClick={onClose}>Fechar</button>
      </div>
    </div>
  );
};

export default MessageModal;
