import React from 'react';
import './ConfirmationModal.css';

const ConfirmationModal = ({ onClose, onAddAnother, onFinish }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h3>O que você deseja fazer?</h3>
        <div className="modal-buttons">
          <button onClick={onAddAnother}>Adicionar Outro Agente</button>
          <button onClick={onFinish}>Finalizar Configuração</button>
        </div>
        <button className="close-button" onClick={onClose}>Fechar</button>
      </div>
    </div>
  );
};

export default ConfirmationModal;
