import axios from 'axios';

const apiClient = axios.create({
  //baseURL: 'http://localhost:3001',
  baseURL: 'http://api-playground.ia4you.com',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': "Bearer a03e7ec2be97a6409b9b411e4c2a90747ba8aebf"
  }
});

// Interceptor para remover headers se a rota contiver "login"
apiClient.interceptors.request.use(config => {
  if (config.url.includes('login')) {
    delete config.headers['Authorization'];
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// Função para formatar chaves para camelCase
const formatKeys = (data) => {
  if (Array.isArray(data)) {
    return data.map(item => formatKeys(item));
  } else if (data !== null && typeof data === 'object') {
    const keys = Object.keys(data);

    // Verifica se todas as chaves são strings numéricas
    const allKeysAreNumeric = keys.every(key => /^\d+$/.test(key));

    if (allKeysAreNumeric) {
      // Converte o objeto em array se todas as chaves forem numéricas
      return keys.sort((a, b) => a - b).map(key => formatKeys(data[key]));
    } else {
      return keys.reduce((acc, key) => {
        const formattedKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
        acc[formattedKey] = formatKeys(data[key]);
        return acc;
      }, {});
    }
  }
  return data;
};

// Função para converter chaves para underscore_case
const underscoreKeys = (data) => {
  if (data !== null && typeof data === 'object') {
    return Object.keys(data).reduce((acc, key) => {
      const underscoredKey = key.replace(/([A-Z])/g, "_$1").toLowerCase();
      acc[underscoredKey] = underscoreKeys(data[key]);
      return acc;
    }, {});
  }
  return data;
};

const handleError = (error) => {
  // Você pode adicionar lógica personalizada de tratamento de erro aqui
  console.error("API error:", error.response ? error.response.data : error.message);
  throw error;
};

const get = async (path) => {
  try {
    const response = await apiClient.get(path);
    return formatKeys(response.data);
  } catch (error) {
    handleError(error);
  }
};

const formatAgentsAsArray = (data) => {
  if (data.agents && typeof data.agents === 'object' && !Array.isArray(data.agents)) {
    const agentsArray = Object.keys(data.agents).map(key => data.agents[key]);
    data.agents = agentsArray;
  }
  return data;
};

const post = async (path, data) => {
  try {
    // Converter agents para array se necessário
    const formattedData = formatAgentsAsArray(data);

    const underscoredData = underscoreKeys(formattedData);
    const response = await apiClient.post(path, underscoredData);
    return formatKeys(response.data);
  } catch (error) {
    handleError(error);
  }
};

const put = async (path, data) => {
  try {
    // Converter agents para array se necessário
    const formattedData = formatAgentsAsArray(data);

    const underscoredData = underscoreKeys(formattedData);
    const response = await apiClient.put(path, underscoredData);
    return formatKeys(response.data);
  } catch (error) {
    handleError(error);
  }
};


const remove = async (path) => {
  try {
    const response = await apiClient.delete(path);
    return formatKeys(response.data);
  } catch (error) {
    handleError(error);
  }
};

const ApiService = {
  get,
  post,
  put,
  delete: remove
};

export default ApiService;
