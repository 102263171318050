import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import './ListConfigs.css';

import ClipLoader from 'react-spinners/ClipLoader';
import ApiService from '../../../../services/apiService';
import { FormContext } from '../../../../context/FormContext';

const ListConfigs = () => {
  const [configs, setConfigs] = useState(null);
  const [loading, setLoading] = useState(true);
  const { formData, setFormData } = useContext(FormContext);
  const navigate = useNavigate();

  useEffect(() => {
    ApiService.get('/api/v1/settings').then((response) => {
      setConfigs(response);
      setLoading(false);
    });

    setFormData({
      id: '',
      name: '',
      iaName: '',
      iaUsed: 'Não escolhida',
      iaVersion: '',
      useAgents: false,
      agents: [],
      globalPrompt: '',
    });
  }, []);

  const handleView = (id) => {
    navigate(`/config/${id}`);
  };

  const handleEdit = (id) => {
    navigate(`/config/${id}/edit`);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-card">
          <ClipLoader color="#1abc9c" loading={loading} size={50} />
          <p>Carregando configurações...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="list-configs-container">
      <h1>Configurações</h1>
      <button onClick={() => navigate('/new-config')} className="new-config-button">Adicionar Configuração</button>

      <table className="configs-table">
        <thead>
          <tr>
            <th>Nome</th>
            <th>LLM Version</th>
            <th>Nome da IA</th>
            <th>Usa Agentes?</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {configs.map((config, index) => (
            <tr key={index}>
              <td>{config.name}</td>
              <td>{config.iaVersion}</td>
              <td>{config.iaName}</td>
              <td>{config.useAgents ? 'Sim' : 'Não'}</td>
              <td>
                <button onClick={() => handleView(config.id)} className="action-button">Ver</button>
                <button onClick={() => handleEdit(config.id)} className="action-button">Editar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListConfigs;
