import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { FormProvider } from './context/FormContext';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/routes/PrivateRoute';

import Layout from './components/layout/layout/Layout';
import ListConfigs from './pages/logged/configs/listConfigs/ListConfigs';
import NewConfig from './pages/logged/configs/newConfig/NewConfig';
import ViewConfig from './pages/logged/configs/viewConfig/ViewConfig';
import EditConfig from './pages/logged/configs/editConfig/EditConfig';

import ListChats from './pages/logged/chats/listChats/ListChats';
import NewChat from './pages/logged/chats/newChat/NewChat';
import ViewChat from './pages/logged/chats/viewChat/ViewChat';

import Login from './pages/unlogged/login/Login';

function App() {
  const [formData, setFormData] = useState({
    iaVersion: '',
    useAgents: false,
    useFunctions: false,
    functionsCode: '',
    supervisorPrompt: '',
    agents: []
  });

  return (
    <AuthProvider>
      <FormProvider>
        <Router>
          <Layout>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route element={<PrivateRoute />}>
                <Route path="/" element={<ListConfigs />} />
                <Route path="/list-configs" element={<ListConfigs />} />
                <Route path="/new-config" element={<NewConfig />} />
                <Route path="/config/:id" element={<ViewConfig />} />
                <Route path="/config/:id/edit" element={<EditConfig />} />
                <Route path="/list-chats" element={<ListChats />} />
                <Route path="/new-chat" element={<NewChat />} />
                <Route path="/chat/:id" element={<ViewChat />} />
              </Route>
            </Routes>
          </Layout>
        </Router>
      </FormProvider>
    </AuthProvider>
  );
}

export default App;
