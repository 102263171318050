import { useState } from 'react';
import './ChatView.css';

import MessageModal from '../messageModal/MessageModal';

const ChatView = ({ messages }) => {
  const [selectedMessage, setSelectedMessage] = useState(null);

  const handleClickMessage = (message) => {
    if (message.role === 'assistant') {
      setSelectedMessage(message);
    }
  }

  const handleCloseModal = () => {
    setSelectedMessage(null);
  }

  return (
    <div className="chat-view">
      {messages.map((message, index) => (
        <div
          key={index}
          className={`chat-message ${
            message.role === 'user' ? 'chat-message-user' : 'chat-message-assistant'
          }`}
          onClick={() => handleClickMessage(message)}
        >
          {message.content}
          <div className="chat-message-info-box">
            <div className="chat-message-role">{`${message.role} `} &nbsp;</div>
            <div className="chat-message-time">
              {message.createdAt}
            </div>
          </div>
        </div>
      ))}
      <MessageModal message={selectedMessage} onClose={handleCloseModal} />
    </div>
  );
};

export default ChatView;
